import { JitsiMeeting } from "@jitsi/react-sdk";
import './Conference.scss'

const ConferencePage = () => {
  return (
    <div className="conference">
      <JitsiMeeting
        domain='https://jitsi.185-229-227-132.cloud-xip.com'
        roomName="PleaseUseAGoodRoomName"
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite = {{
          VIDEO_LAYOUT_FIT: 'nocrop',
          MOBILE_APP_PROMO: false,
          TILE_VIEW_MAX_COLUMNS: 4
      }}
        userInfo={{
          displayName: "YOUR_USERNAME",
        }}
        onApiReady={(externalApi) => {
          // here you can attach custom event listeners to the Jitsi Meet External API
          // you can also store it locally to execute commands
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "400px";
        }}
      />
    </div>
  );
};

export default ConferencePage;
